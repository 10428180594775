<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <p>
          <strong>Info Attività: </strong>
          <span>ID notifica richiesta -> <strong id="id_passato">{{ id_passato }}</strong></span>
        </p>
      </div>
    </b-alert>
  </div>
</template>

<script>
import router from '@/router'
import { ref, watch, computed, } from '@vue/composition-api'
import { BAlert } from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BAlert,
  },
  setup() {
    // fetch the user information when params change
    const { route } = useRouter()
    const routeParams = computed(() => route.value.params)

    //start pagina
    const id_passato = ref()
    cambioIdRiga(routeParams.value.id_riga);

    //in caso di cambio scheda attività tramite la notifiche in header
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      ///console.log("fetchTasks sul watch routeParams ....")
      //console.log(routeParams.value.id_riga)

      cambioIdRiga(routeParams.value.id_riga);
    })

    function cambioIdRiga(id_nuova_riga){
        console.log("id_nuova_riga -> "+id_nuova_riga)
        id_passato.value = id_nuova_riga;

        console.log("poi eseguo la chiamata all'api per recupera i dati dell'attività richiesta in base all'ID")
    }

    return {
        id_passato,
    }
  },
  methods: {
    
  }
}
</script>
